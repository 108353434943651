function pathTo(resource) {
  const env = process.env.ENV || process.env.NODE_ENV
  let path = ''
  const locale = resource.locale.toLowerCase()
  const slug = locale === 'de' ? resource.slugDe : resource.slugEn

  if (resource.slugDe === 'home') {
    return `/${locale}`
  }

  switch (resource.__typename) {
    case 'CMSCookbookPost':
      return `/${locale}/vegan-durch-den-tag-hoerbuch/${resource.slugDe}`
    case 'CMSCategory':
      path = locale === 'de' ? 'kategorien' : 'categories'
      return `/${locale}/${path}/${slug}`
    case 'CMSPage':
      path = slug === '/' ? '/' : `/${slug}`
      return `/${locale}${path}`
    case 'CMSPost':
      if (env === 'preview') return `/de/artikel/${slug}`
      path = locale === 'de' ? 'artikel' : 'posts'
      if (resource.series) {
        const seriesSlug = locale === 'de' ? resource.series.slugDe : resource.series.slugEn
        path = `${path}/${seriesSlug}`
      }
      return `/${locale}/${path}/${slug}`
    case 'CMSRecipe':
      path = locale === 'de' ? 'rezepte' : 'recipes'
      return `/${locale}/${path}/${slug}`
    case 'CMSSeries':
      path = locale === 'de' ? 'artikel' : 'posts'
      return `/${locale}/${path}/${slug}`
    case 'ContentfulNavigationItem':
      return slug
    // case 'ShopifyProduct':
    //   return `/de/shop/${resource.slug}`
    default:
      return `/${locale}/${slug}`
  }
}

module.exports.pathTo = pathTo
